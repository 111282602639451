<template>
  <div class="navbar" style="height:50px;">
    <n-menu
      :value="activeKey"
      mode="horizontal"
      :options="menuOptions"
      @update:value="handleUpdateValue"
      style="--n-item-text-color-horizontal:white;font-weight:bolder;float:left;"
    />
  </div>
</template>

<script>
// import { defineComponent } from "vue";
import { useMessage } from "naive-ui";
// import { NIcon } from "naive-ui";
import { h } from "vue";
import { renderIcon } from "@/utils/utils";
import {
  HomeOutline as HomeIcon,
  // LogOutOutline as LogOutIcon
} from "@vicons/ionicons5";
// function renderIcon(icon) {
//   return () => h(NIcon, null, { default: () => h(icon) });
// }
const menuOptions = [
  {
    label: () => h("a", {
      href: "/#/",
      // target: "_blank",
      // rel: "noopenner noreferrer"
    }, "首页"),
    key: "TranslationList",
    icon: renderIcon(HomeIcon)
  },
  // {
  //   label: () => h("a", {
  //     href: "/#/translations/create",
  //     // target: "_blank",
  //     // rel: "noopenner noreferrer"
  //   }, "导入"),
  //   key: "CreateTranslations",
  //   icon: renderIcon(LogOutIcon)
  // },
  // {
  //   label: () => h("a", {
  //     href: "/#/user/list",
  //     // target: "_blank",
  //     // rel: "noopenner noreferrer"
  //   }, "用户"),
  //   key: "UserList",
  //   icon: renderIcon(LogOutIcon)
  // },
  // {
  //   label: () => h("a", {
  //     href: "/#/user/logout",
  //     // target: "_blank",
  //     // rel: "noopenner noreferrer"
  //   }, "Logout"),
  //   key: "logout",
  //   icon: renderIcon(LogOutIcon)
  // },
]
export default {
  data() {
    return {
      // activeKey: 'home'
    }
  },
  setup() {
    window.$message = useMessage()
    return {
      menuOptions,
      info() {
        // message.info("I don't know why nobody told you how to unfold your love", {
        //   keepAliveOnHover: true
        // });
      },
      // error() {
      //   message.error("Once upon a time you dressed so fine");
      // },
      warning() {
        // message.warning("How many roads must a man walk down");
      },
      success() {
        // message.success("'Cause you walked hand in hand With another man in my place");
      },
      loading() {
        // message.loading("If I were you, I will realize that I love you more than any other guy");
      },
    };
  },
  computed: {
    activeKey() {
      // console.log(this.$route.name)
      return this.$route.name
    }
  },
  methods: {
    error() {
      window.$message.error("")
    },
    handleUpdateValue (/*key, item*/) {
        // console.log(key, item)
        // this.activeKey = key
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.navbar {
  background: rgb(14, 8, 8);
  color:white;
  float:left;
  width:100%;
}

</style>
