<template>
  <n-space vertical>
    <h1>进制转化计算器</h1>

    <n-table :bordered="true" :single-line="false" style="width:auto;margin:10px auto;font-size:20px;">
      <tbody>
        <tr>
          <td>
            <canvas :width="canvasWidth" :height="canvasWidth" @click="download()" ref="cv-icon"></canvas>
          </td>
          <td style="vertical-align: top;">
            <n-table :bordered="false" :single-line="false" style="width:auto;margin-top:0;font-size:20px;">
              <tbody>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">文字</span>
                    <n-input
                      v-model:value="word"
                      placeholder=""
                      style="width:80px;margin-left:15px;"
                      type="text"
                      @update:value="onWordChanged"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">文字颜色</span>
                    <n-color-picker v-model:value="wordColor" :show-alpha="true" :modes="['hex']"
                      style="float:left;width:100px;margin-left:10px;" @update:value="refreshIcon()"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">背景颜色</span>
                    <n-color-picker v-model:value="bgColor" :show-alpha="true" :modes="['hex']"
                      style="float:left;width:100px;margin-left:10px;" @update:value="refreshIcon()"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">边框颜色</span>
                    <n-color-picker v-model:value="borderColor" :show-alpha="true" :modes="['hex']"
                      style="float:left;width:100px;margin-left:10px;" @update:value="refreshIcon()"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="float:left;">形状</span>
                    <n-radio-group v-model:value="borderStyle"
                      name="borderStyleRadioGroup"
                      size="medium"
                      style="float:left;margin-left:10px;"
                      @update:value="refreshIcon()">
                      <n-radio-button
                        key="default"
                        value="default"
                      >
                        默认
                      </n-radio-button>
                      <n-radio-button
                        key="round"
                        value="round"
                      >
                        圆形
                      </n-radio-button>
                      <n-radio-button
                        key="roundRect"
                        value="roundRect"
                      >
                        圆角
                      </n-radio-button>
                    </n-radio-group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">显示边框</span>
                    <n-switch v-model:value="showBorder" @update:value="refreshIcon()"
                      style="float:left;margin-right:5px;margin-top:5px;"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">显示阴影</span>
                    <n-switch v-model:value="showShadow" @update:value="refreshIcon()"
                      style="float:left;margin-right:5px;margin-top:5px;"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">背景色渐变</span>
                    <n-switch v-model:value="bgLinear" @update:value="refreshIcon()"
                      style="float:left;margin-right:5px;margin-top:5px;"/>
                  </td>
                </tr>
              </tbody>
             </n-table>
          </td>
        </tr>
      </tbody>
    </n-table>

  </n-space>
</template>
<script>
// import { defineComponent } from "vue";
// import { h, ref, reactive } from "vue";
// import { ref } from "vue";
import { useMessage } from "naive-ui";
import { colord } from "colord";

export default {
  components: {
  },
  data() {
      return {
        word: '饭',
        bgColor: '#0000FF',
        borderColor: '#000000',
        wordColor: '#FFFFFF',
        borderWidth: 15,
        canvasWidth: 500,
        showShadow: true,
        showBorder: true,
        bgLinear: true,
        borderStyle: 'default',
      }
  },
  setup() {
    window.$message = useMessage()
    return {
    };
  },
  mounted() {
    this.refreshIcon()
  },
  methods: {
    error() {
      window.$message.error("")
    },
    refreshIcon() {
      this.borderWidth = Math.floor(this.canvasWidth*0.03)
      const canvas = this.$refs['cv-icon']
      const ctx = canvas.getContext('2d')
      this.drawBackground(ctx)
      this.drawBorder(ctx)
      this.drawWord(ctx)
    },
    drawBackground(ctx) {
      ctx.strokeStyle = '#FFFFFF00'
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasWidth);

      if (this.bgLinear) {
        const grad = this.getBgLinearGradient(ctx)  //定义渐变色颜色
        ctx.fillStyle = grad                         //设置fillStyle为当前的渐变对象
      } else {
         ctx.fillStyle = this.bgColor
      }
      
      if (this.borderStyle == 'roundRect') {
        ctx = this.makeRoundRectPath(ctx)
        ctx.fill()
      } else if (this.borderStyle == 'round') {
        ctx = this.makeRoundPath(ctx)
        ctx.fill()
      } else {
        ctx.fillRect(0, 0, this.canvasWidth, this.canvasWidth)
      }
    },
    getBgLinearGradient(ctx) {
      let grad = ctx.createLinearGradient(0, 0, this.canvasWidth, this.canvasWidth); //创建一个渐变色线性对象
      let rgba = colord(this.bgColor)
      rgba.rgba.r = rgba.rgba.r - 100 < 0 ? 0 : rgba.rgba.r - 100
      rgba.rgba.g = rgba.rgba.g - 100 < 0 ? 0 : rgba.rgba.g - 100
      rgba.rgba.b = rgba.rgba.b - 100 < 0 ? 0 : rgba.rgba.b - 100
      const bgColor = rgba.toHex()

      grad.addColorStop(0, this.bgColor)
      grad.addColorStop(1, bgColor)

      return grad
    },
    makeRoundRectPath(ctx) {
      const cornerRedius = Math.floor(this.canvasWidth/10)
      const offset = this.borderWidth/2
      ctx.beginPath()
      ctx.arc(offset + cornerRedius, offset + cornerRedius, cornerRedius, Math.PI, 1.5*Math.PI)
      ctx.arc(this.canvasWidth - offset - cornerRedius, offset + cornerRedius, cornerRedius, 1.5*Math.PI, 2*Math.PI)
      ctx.arc(this.canvasWidth - offset - cornerRedius, this.canvasWidth - offset - cornerRedius, cornerRedius, 0, 0.5*Math.PI)
      ctx.arc(offset + cornerRedius, this.canvasWidth - offset - cornerRedius, cornerRedius, 0.5*Math.PI, 1*Math.PI)
      ctx.lineTo(offset, offset + cornerRedius)
      ctx.closePath()
      return ctx
    },
    makeRoundPath(ctx) {
      const offset = this.borderWidth/2
      const redius = this.canvasWidth/2 - offset
      ctx.beginPath()
      ctx.arc(this.canvasWidth/2, this.canvasWidth/2, redius, 0, 2*Math.PI)
      ctx.closePath()
      return ctx
    },
    drawBorder(ctx) {
      if (!this.showBorder) {
        return
      }
      ctx.strokeStyle = this.borderColor
      ctx.lineWidth = this.borderWidth
      let offset = this.borderWidth/2
      if (this.borderStyle == 'roundRect') {
          ctx = this.makeRoundRectPath(ctx)
      } else if (this.borderStyle == 'round') {
          ctx = this.makeRoundPath(ctx)
      } else {
        offset = this.borderWidth/2
        ctx.lineWidth = this.borderWidth
        ctx.beginPath()
        ctx.moveTo(0, offset)
        ctx.lineTo(this.canvasWidth - offset, offset)
        ctx.lineTo(this.canvasWidth - offset, this.canvasWidth - offset)
        ctx.lineTo(offset, this.canvasWidth - offset)
        ctx.lineTo(offset, 0)
        ctx.closePath()
      }
      ctx.stroke()
    },
    drawWord(ctx) {
      if (this.word.length < 1) {
        return
      }
      const word = this.word[0]
      const fontSize = Math.floor(this.canvasWidth*0.7)
      ctx.font = fontSize+'px "微软雅黑"';           //设置字体
      const wordWidth  = (ctx.measureText(word).width)
      const wordX = this.canvasWidth/2 - wordWidth/2
      const wordY = this.canvasWidth/2 + fontSize/3
      if (this.showShadow) {
        const shadowColor = '#00000044'
        const shadowOffset = Math.floor(fontSize*0.05)
        ctx.fillStyle = shadowColor
        ctx.fillText(word, wordX + shadowOffset, wordY + shadowOffset)
      }
      ctx.fillStyle = this.wordColor
      ctx.fillText(word, wordX, wordY)
      ctx.stroke()
    },
    onWordChanged() {
      this.refreshIcon()
    },
    download() {
      const data = this.$refs['cv-icon'].toDataURL('image/png')
      var a = document.createElement("a"); //Create <a>
      a.href = data; //Image Base64 Goes here
      a.download = "icon.png"; //File name Here
      a.click(); //Downloaded file
    }
  },
  computed: {
    showXXX() {
      // return true
      // 是否部分选择
      return true
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.my-icon {
  color:black;
}
.my-icon:hover {
  /* color:#42b983; */
  color:var(--my-color-green);
}
.td-number:hover {
  cursor: pointer;
}
</style>
