// import VueRouter from 'vue-router'
// const About = { template: '<div>About</div>' }
// import HomePage from '../components/HomePage.vue'
// import NavBar from '../components/NavBar.vue'
import MainPage from '../views/MainPage.vue'
import MainLayout from '../views/layout/MainLayout.vue'
import TimeStamp from '../views/datetime/TimeStamp.vue'
import TimeCalculator from '../views/datetime/TimeCalculator.vue'
import UuId from '../views/idgen/UuId.vue'
import NanoId from '../views/idgen/NanoId.vue'
import FlakeId from '../views/idgen/FlakeId.vue'
import QrCode from '../views/barcode/QrCode.vue'
import BarCode from '../views/barcode/BarCode.vue'
import BaseCalculator from '../views/calculator/BaseCalculator.vue'
import FanfouIcon from '../views/icon/FanfouIcon.vue'
import CapFourIcon from '../views/icon/CapFourIcon.vue'
import PornHubIcon from '../views/icon/PornHubIcon.vue'


import {createRouter, createWebHashHistory} from 'vue-router'
// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
  {
    name: 'Home',
    path: '/',
    component: MainLayout,
    children: [
      { name: 'MainPage', path: '/', component: MainPage },
    ],
  },
  {
    name: 'Datetime',
    path: '/datetime',
    component: MainLayout,
    children: [
      { name: 'TimeStamp', path: '/datetime/timestamp', component: TimeStamp },
      { name: 'TimeCalculator', path: '/datetime/calculator', component: TimeCalculator },
    ],
  },
  {
    name: 'IdGen',
    path: '/idgen',
    component: MainLayout,
    children: [
      { name: 'UuId', path: '/idgen/uuid', component: UuId },
      { name: 'NanoId', path: '/idgen/nanoid', component: NanoId },
      { name: 'FlakeId', path: '/idgen/flakeid', component: FlakeId },
    ],
  },
  {
    name: 'BarCodeBase',
    path: '/barcode',
    component: MainLayout,
    children: [
      { name: 'BarCode', path: '/barcode/bar', component: BarCode },
      { name: 'QrCode', path: '/barcode/qr', component: QrCode },
    ],
  },
  {
    name: 'Calculator',
    path: '/calculator',
    component: MainLayout,
    children: [
      { name: 'BaseCalculator', path: '/calculator/base', component: BaseCalculator },
    ],
  },
  {
    name: 'Icon',
    path: '/icon',
    component: MainLayout,
    children: [
      { name: 'FanfouIcon', path: '/icon/fanfou', component: FanfouIcon },
      { name: 'CapFourIcon', path: '/icon/cap4', component: CapFourIcon },
      { name: 'PornHubIcon', path: '/icon/pornhub', component: PornHubIcon },
    ],
  },
  // {
  //   name: 'NoteParent',
  //   path: '/note',
  //   component: MainLayout,
  //   children: [
  //     { name: 'NoteList', path: '/note/list', component: NoteList },
  //     { name: 'DeletedNoteList', path: '/note/deleted', component: DeletedNoteList },
  //   ],
  // },
]

// export default new VueRouter({
//     scrollBehavior: () => ({ y: 0 }),
//     // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
//     history: VueRouter.createWebHashHistory(),
//     routes: routes
// });

const router = createRouter({
  scrollBehavior: () => ({ y: 0 }),
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes: routes
});
export default router;
