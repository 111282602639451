<template>
  <n-space vertical>
    <n-table :bordered="true" :single-line="false" style="width:auto;margin:10px auto;font-size:20px;">
      <tbody>
        <tr>
          <td>
            相差秒数
          </td>
          <td>
            <n-date-picker
              v-model:value="diffTimeX"
              type="datetime"
              clearable
              style="float:left;margin-top:2px;"/>
            <n-icon style="margin-top:5px;margin-left:10px;margin-right:10px;float:left;" size="30">
              <remove-circle-outline class="my-icon" />
            </n-icon>
            <n-date-picker
              v-model:value="diffTimeY"
              type="datetime"
              clearable
              style="float:left;margin-top:2px;"/>
          </td>
          <td>{{ diffSecResult }}</td>
        </tr>
        <tr>
          <td>
            加秒数
          </td>
          <td>
            <n-date-picker
              v-model:value="plusTimeX"
              type="datetime"
              clearable
              style="float:left;margin-top:2px;"/>
            <n-icon style="margin-top:5px;margin-left:10px;margin-right:10px;float:left;" size="30">
              <add-circle-outline class="my-icon" />
            </n-icon>
            <n-input-number v-model:value="plusSec" style="margin-top:5px;margin-left:0px;width:200px;margin-right:10px;float:left;"/>
          </td>
          <td>{{ plusTimeResult }}</td>
        </tr>
        <tr>
          <td>
            相差天数
          </td>
          <td>
            <n-date-picker
              v-model:formatted-value="diffDayX"
              value-format="yyyy-MM-dd"
              type="date"
              style="float:left;margin-top:2px;"/>
            <n-icon style="margin-top:5px;margin-left:10px;margin-right:10px;float:left;" size="30">
              <remove-circle-outline class="my-icon" />
            </n-icon>
            <n-date-picker
              v-model:formatted-value="diffDayY"
              value-format="yyyy-MM-dd"
              type="date"
              clearable
              style="float:left;margin-top:2px;"/>
          </td>
          <td>{{ diffDayResult }}</td>
        </tr>
        <tr>
          <td>
            加天数
          </td>
          <td>
            <n-date-picker
              v-model:value="plusDayX"
              type="date"
              style="float:left;margin-top:2px;"/>
            <n-icon style="margin-top:5px;margin-left:10px;margin-right:10px;float:left;" size="30">
              <add-circle-outline class="my-icon" />
            </n-icon>
            <n-input-number v-model:value="plusDay" style="margin-top:5px;margin-left:0px;width:200px;margin-right:10px;float:left;"/>
          </td>
          <td>{{ plusDayResult }}</td>
        </tr>
      </tbody>
    </n-table>


  </n-space>
</template>
<script>
// import { defineComponent } from "vue";
// import { h, ref, reactive } from "vue";
// import { ref } from "vue";
import { useMessage } from "naive-ui";
import moment from "moment"
import { RemoveCircleOutline, AddCircleOutline } from "@vicons/ionicons5";
// import { ArrowForwardOutline, CaretForwardCircleOutline, ClipboardOutline, PauseCircleOutline, TimeOutline } from "@vicons/ionicons5";
import useClipboard from 'vue-clipboard3'

export default {
  components: {
    'remove-circle-outline': RemoveCircleOutline,
    'add-circle-outline': AddCircleOutline,
    // 'caret-forward-circle-outline': CaretForwardCircleOutline,
    // 'pause-circle-outline': PauseCircleOutline,
    // 'clipboard-outline': ClipboardOutline,
    // 'arrow-forward-outline': ArrowForwardOutline,
    // 'time-outline': TimeOutline,
  },
  data() {
      return {
        timestamp: 0,
        inputTimestamp: 0,
        diffTimeX: 0,
        diffTimeY: 0,
        plusTimeX: 0,
        plusSec: 0,
        plusDay: 0,
        diffDayX: '2000-01-01',
        diffDayY: '2000-01-01',
        plusDayX: 0,
      }
  },
  setup() {
    window.$message = useMessage()
    return {
    };
  },
  mounted() {
    // this.getList()
    this.timestamp = (new Date).getTime()
    this.diffTimeX = this.timestamp
    this.diffTimeY = this.timestamp - (3600*24)*1000
    this.plusTimeX = this.timestamp
    this.diffDayX = moment(this.timestamp).format('YYYY-MM-DD')
    this.diffDayY = moment(this.timestamp - (3600*24)*1000).format('YYYY-MM-DD')
    this.plusDayX = this.timestamp

    this.timerStopped = true
    this.pickerDateTime = moment(this.timestamp*1000).format('YYYY-MM-DD HH:mm:ss')
    this.startTimer()
  },
  methods: {
    error() {
      window.$message.error("")
    },
    startTimer() {
      if (!this.timerStopped) {
        return
      }
      this.timerStopped = false
      // 将定时器名字赋值到变量中
      this.timer = setInterval(() => {
        this.timestamp = parseInt((new Date).getTime()/1000)
      }, 1000);
    },
    stopTimer() {
      if (this.timerStopped) {
        return
      }
      this.timerStopped = true
      clearInterval(this.timer);
      this.timer = null // 这里最好清除一下，回归默认值
      // 众所周知，定时器返回一个随机整数，用于表示定时器的编号，后面通过名字可以取消这个定时器的执行。
      // console.log(this.timer);
    },
    onClickStartTimer() {
      this.startTimer()
    },
    onClickStopTimer() {
      this.stopTimer()
    },
    onClickCopy(arg) {
      const { toClipboard } = useClipboard();
      toClipboard(arg)
    },
    onClickNowTimestamp() {
      this.inputTimestamp = this.timestamp
    },
    secToDays(sec) {
      console.log('secToDays')
      console.log(sec)
      const sign = sec >= 0 ? 1 : -1
      let absSec = Math.abs(sec)
      let days = parseInt(absSec/(3600*24))
      absSec = absSec - days*(3600*24)
      if (absSec && sign>0) {
        days++
      }
      return sign*days
    },
    getDateDays(date) {
      const offset = moment().utcOffset()/60
      const sec = moment(date+' 00:00:00').unix() + 3600*offset
      const sign = sec >=0 ? 1 : -1
      const absSec = Math.abs(sec)
      let days = 0
      if (sign > 0) {
        const days1 = parseInt(absSec/(3600*24))
        days = days1
        if ((absSec - days1*(3600*24)) >= 0) {
          days++
        }
      } else {
        const days1 = parseInt(absSec/(3600*24))
        days = days1
        if ((absSec - days1*(3600*24)) > 0) {
          days++
        }
      }
      
      return days*sign
    }
  },
  computed: {
    utcDateTime() {
      return moment(this.timestamp*1000).utc().format('YYYY-MM-DD HH:mm:ss')
    },
    localDateTime() {
      return moment(this.timestamp*1000).format('YYYY-MM-DD HH:mm:ss')
    },
    localOffset() {
      const offset = moment().utcOffset()/60
      return offset
    },
    localTimestampDateTime() {
      return moment(this.inputTimestamp*1000).format('YYYY-MM-DD HH:mm:ss')
    },
    timestampDateTime() {
      return moment(this.inputTimestamp*1000).utcOffset(this.offset2).format('YYYY-MM-DD HH:mm:ss')
    },
    datetimeTimestamp() {
      return moment(this.pickerDateTime).unix()+(this.localOffset-this.offset3)*3600
    },
    diffSecResult() {
      const msec = Math.abs(this.diffTimeX - this.diffTimeY)
      let sec = parseInt(msec/1000)
      const secAll = sec
      const day = parseInt(sec/(3600*24))
      sec = sec - day*3600*24
      const hour = parseInt(sec/3600)
      sec = sec - hour*3600
      const min = parseInt(sec/60)
      sec = sec - min*60
      return secAll+' 秒 | ' + day + ' 天 ' + hour + ' 小时 ' + min + ' 分钟 ' + sec + ' 秒'
    },
    diffDayResult() {
      let dayX = this.getDateDays(this.diffDayX)
      let dayY = this.getDateDays(this.diffDayY)
      dayX = dayX < 0 ? dayX + 1 : dayX
      dayY = dayY < 0 ? dayY + 1 : dayY
      let day = Math.abs(dayX - dayY)
      const dayAll = day
      const week = parseInt(day/7)
      day = day - week*7
      return dayAll+' 天 | ' + week + ' 周 ' + day + ' 天'
    },
    plusTimeResult() {
      const time = this.plusTimeX + this.plusSec*1000
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    plusDayResult() {
      const time = this.plusDay*3600*24*1000 + this.plusDayX
      return moment(time).format('YYYY-MM-DD')
    },
    showXXX() {
      // return true
      // 是否部分选择
      return true
    }
  },
  beforeUnmount() {
    // js提供的clearInterval方法用来清除定时器
    clearInterval(this.timer);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.my-icon {
  color:black;
}
.my-icon:hover {
  /* color:#42b983; */
  color:var(--my-color-green);
}
</style>
