<template>
  <n-space vertical>
    <h1>二维码生成器</h1>

    <n-table :bordered="true" :single-line="false" style="width:auto;margin:10px auto;font-size:20px;">
      <tbody>
        <tr>
          <td colspan="2">
            <span style="float:left;">容错率</span>
            <n-radio-group v-model:value="errorCorrectionLevel"
                name="errorCorrectionLevelRadioGroup"
                size="small"
                style="float:left;margin-left:10px;"
                @update:value="refreshCode()">
                <n-radio-button
                  key="L"
                  value="L"
                >
                  低
                </n-radio-button>
                <n-radio-button
                  key="M"
                  value="M"
                >
                  中
                </n-radio-button>
                <n-radio-button
                  key="Q"
                  value="Q"
                >
                  中+
                </n-radio-button>
                <n-radio-button
                  key="H"
                  value="H"
                >
                  高
                </n-radio-button>
              </n-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <span style="float:left;">定位点形状</span>
            <n-radio-group v-model:value="anchorStyle"
              name="anchorStyleRadioGroup"
              size="medium"
              style="float:left;margin-left:10px;"
              @update:value="refreshCode()">
              <n-radio-button
                key="default"
                value="default"
              >
                默认
              </n-radio-button>
              <n-radio-button
                key="round"
                value="round"
              >
                圆形
              </n-radio-button>
              <n-radio-button
                key="roundRect"
                value="roundRect"
              >
                圆角
              </n-radio-button>
            </n-radio-group>
          </td>
        </tr>
        <tr>
          <td>
            <n-upload
              :max="1"
              :default-upload="false"
              @change="handleLogoChanged"
            >
              <n-button>上传LOGO</n-button>
            </n-upload>
          </td>
          <td>
            <canvas width="150" height="150" ref="cv-logo"></canvas>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <span style="float:left;">LOGO形状</span>
            <n-radio-group v-model:value="logoStyle"
                name="logoStyleRadioGroup"
                size="medium"
                style="float:left;margin-left:10px;"
                @update:value="refreshCode()">
                <n-radio-button
                  key="default"
                  value="default"
                >
                  默认
                </n-radio-button>
                <n-radio-button
                  key="round"
                  value="round"
                >
                  圆形
                </n-radio-button>
                <n-radio-button
                  key="roundRect"
                  value="roundRect"
                >
                  圆角
                </n-radio-button>
              </n-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <n-input
              v-model:value="content"
              placeholder=""
              type="textarea"
              clearable
              @update:value="onContentChanged"
            />
          </td>
        </tr>
        <tr>
          <td >
            <span style="float:left;">线条颜色</span>
            <n-color-picker v-model:value="lineColor" :show-alpha="true" :modes="['hex']" style="float:left;width:100px;margin-left:10px;" @update:value="refreshCode()"/>
          </td>
          <td >
            <span style="float:left;">背景颜色</span>
            <n-color-picker v-model:value="bgColor" :show-alpha="true" :modes="['hex']" style="float:left;width:100px;margin-left:10px;" @update:value="refreshCode()"/>
          </td>
        </tr>

        <tr>
          <td colspan="2">
            <canvas :width="canvasWidth" :height="canvasWidth" @click="download()" class="td-canvas" ref="cv-qrcode" :hidden="hideCanvas"></canvas>
          </td>
        </tr>
      </tbody>
    </n-table>

    <div>
      
    </div>

  </n-space>
</template>
<script>
// import { defineComponent } from "vue";
// import { h, ref, reactive } from "vue";
// import { ref } from "vue";
import { useMessage } from "naive-ui";
import QRCode from 'qrcode';
import { colord } from "colord";


export default {
  components: {
  },
  data() {
      return {
        hideCanvas: false,
        canvasWidth: 600,
        quality: 90,
        margin: 0,
        scale: 5,
        errorCorrectionLevel: 'M',
        imageData: '',
        dataURL: '',
        logoDataURL: '',
        content: 'Hello World.',
        lineColor: '#000000FF',
        bgColor: '#FFFFFFFF',
        anchorStyle: 'default',
        logoStyle: 'default',
      }
  },
  setup() {
    window.$message = useMessage()
    return {
    };
  },
  mounted() {
    this.refreshCode()
  },
  methods: {
    error() {
      window.$message.error("")
    },
    refreshLogoPreview() {
      const canvas = this.$refs['cv-logo']
      const ctx = canvas.getContext('2d');
      const img = new Image()
      img.onload = () => {
        ctx.drawImage(img, 0, 0, 150, 150)
      }
      img.src = this.logoDataURL
    },
    attachLogo() {
      if (this.logoDataURL == '') {
        return
      }
      const canvas = this.$refs['cv-qrcode']
      const ctx = canvas.getContext('2d')

      let img = new Image()
      img.onload = () => {
        const logoWidth = Math.round(this.canvasWidth*0.25)
        const left = Math.round((this.canvasWidth - logoWidth)/2)
        const top = left
        if (this.logoStyle === 'round') {
          ctx.save();
          ctx.beginPath();
          ctx.arc(this.canvasWidth/2, this.canvasWidth/2, logoWidth/2, 0, Math.PI * 2, false);
          ctx.clip();
          ctx.drawImage(img, left, top, logoWidth, logoWidth)
          ctx.restore();
        } else if (this.logoStyle === 'roundRect') {
          const centerX = this.canvasWidth/2
          const centerY = this.canvasWidth/2
          let redius = Math.round(logoWidth/4)
          ctx.save();
          ctx.beginPath();
          ctx.arc(centerX - logoWidth/2 + redius, centerY - logoWidth/2 + redius, redius, Math.PI, 1.5*Math.PI)
          ctx.arc(centerX + logoWidth/2 - redius, centerY - logoWidth/2 + redius, redius, 1.5*Math.PI, 2*Math.PI)
          ctx.arc(centerX + logoWidth/2 - redius, centerY + logoWidth/2 - redius, redius, 0*Math.PI, 0.5*Math.PI)
          ctx.arc(centerX - logoWidth/2 + redius, centerY + logoWidth/2 - redius, redius, 0.5*Math.PI, 1*Math.PI)
          ctx.clip();
          ctx.drawImage(img, left, top, logoWidth, logoWidth)
          ctx.restore();
        } else {
          ctx.drawImage(img, left, top, logoWidth, logoWidth)
        }
      }
      img.src = this.logoDataURL
    },
    redrawAnchor() {
      const matrixAnchorWidth = this.getMatrixAnchorWidth()
      let redius = Math.round(matrixAnchorWidth/2)
      switch (this.anchorStyle) {
        case 'round':
          this.redrawRoundAnchor(redius, redius, matrixAnchorWidth)
          this.redrawRoundAnchor(this.canvasWidth - redius, redius, matrixAnchorWidth)
          this.redrawRoundAnchor(redius, this.canvasWidth - redius, matrixAnchorWidth)
          break
        case 'roundRect':
          this.redrawRoundRectAnchor(redius, redius, matrixAnchorWidth)
          this.redrawRoundRectAnchor(this.canvasWidth - redius, redius, matrixAnchorWidth)
          this.redrawRoundRectAnchor(redius, this.canvasWidth - redius, matrixAnchorWidth)
          break
        case 'default':
          break
      }

    },
    redrawRoundAnchor(centerX, centerY, matrixAnchorWidth) {
      const canvas = this.$refs['cv-qrcode']
      const ctx = canvas.getContext('2d')
      const matrixPointWidth = this.getMatrixPointWidth(matrixAnchorWidth)
      let redius = Math.round(matrixAnchorWidth/2)
      let suqareWidth = matrixPointWidth * 3

      ctx.fillStyle = this.bgColor;
      ctx.fillRect(
        centerX - redius - matrixPointWidth/2,
        centerY - redius - matrixPointWidth/2,
        matrixAnchorWidth + matrixPointWidth,
        matrixAnchorWidth + matrixPointWidth)

      ctx.lineWidth = matrixPointWidth
      ctx.beginPath()
      ctx.arc(centerX, centerY, redius-matrixPointWidth/2, 0, 2*Math.PI)
      ctx.strokeStyle = this.lineColor
      ctx.stroke()

      ctx.fillStyle = this.lineColor
      ctx.fillRect(
        centerX - Math.round(suqareWidth/2),
        centerY - Math.round(suqareWidth/2),
        suqareWidth,
        suqareWidth
      )
    },
    redrawRoundRectAnchor(centerX, centerY, matrixAnchorWidth) {
      const canvas = this.$refs['cv-qrcode']
      const ctx = canvas.getContext('2d')
      const matrixPointWidth = this.getMatrixPointWidth(matrixAnchorWidth)
      let redius = Math.floor(matrixAnchorWidth/2)
      let suqareWidth = matrixPointWidth * 3

      ctx.fillStyle = this.bgColor
      ctx.fillRect(
        centerX - redius - matrixPointWidth/2 -1,
        centerY - redius - matrixPointWidth/2 -1,
        matrixAnchorWidth + matrixPointWidth + 2,
        matrixAnchorWidth + matrixPointWidth + 2
      )

      ctx.lineWidth = matrixPointWidth
      ctx.beginPath()
      ctx.arc(centerX - matrixPointWidth*2, centerY - matrixPointWidth*2, matrixPointWidth, Math.PI, 1.5*Math.PI)
      ctx.arc(centerX + matrixPointWidth*2, centerY - matrixPointWidth*2, matrixPointWidth, 1.5*Math.PI, 2*Math.PI)
      ctx.arc(centerX + matrixPointWidth*2, centerY + matrixPointWidth*2, matrixPointWidth, 0, 0.5*Math.PI)
      ctx.arc(centerX - matrixPointWidth*2, centerY + matrixPointWidth*2, matrixPointWidth, 0.5*Math.PI, Math.PI)
      ctx.lineTo(centerX - matrixPointWidth*3, centerY - matrixPointWidth*2);
      ctx.strokeStyle = this.lineColor
      ctx.stroke()

      ctx.fillStyle = this.lineColor
      ctx.fillRect(
        centerX - Math.round(suqareWidth/2),
        centerY - Math.round(suqareWidth/2),
        suqareWidth,
        suqareWidth
      )
    },
    refreshCode() {
      if(this.content === '') {
        this.hideCanvas = true
        return
      } else {
         this.hideCanvas = false
      }
      const canvas = this.$refs['cv-qrcode']
      QRCode.toCanvas(
        canvas,
        this.content,
        {
          errorCorrectionLevel: this.errorCorrectionLevel,
          type: 'image/png',
          quality: 0.95,
          margin: this.margin,
          scale: this.scale,
          width: this.canvasWidth,
          color: {
            dark: this.lineColor,
            light: this.bgColor
          }
        },
        function (error) {
        if (error) console.error(error)
        // console.log('success!')
      })

      this.attachLogo()
      this.redrawAnchor()
      
    },
    onContentChanged(/*val*/) {
      this.refreshCode()
    },
    onTextChanged(/*val*/) {
      this.refreshCode()
    },
    handleLogoChanged(opt) {
      const fileList = opt.fileList
      if (fileList.length > 0) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.logoDataURL = e.target.result
          this.refreshLogoPreview()
          this.refreshCode()
        }
        reader.readAsDataURL(fileList[0].file)
      } else {
        const canvas = this.$refs['cv-logo']
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        this.logoDataURL = ''
        this.refreshCode()
      }
    },
    getMatrixAnchorWidth() {
      const canvas = this.$refs['cv-qrcode']
      const ctx = canvas.getContext('2d');
      
      const rgba = colord(this.lineColor).rgba
      let width = 0
      for (let i=0;i<this.canvasWidth;i++) {
        const imgData = ctx.getImageData(i, 0, 1, 1)
        const r = imgData.data[0]
        const g = imgData.data[1]
        const b = imgData.data[2]
        if (r == rgba.r && g == rgba.g && b == rgba.b) {
          width++
        } else {
          break
        }
      }
      return width
    },
    getMatrixPointWidth(matrixAnchorWidth) {
      return Math.floor(matrixAnchorWidth/7)
    },
    download() {
      const data = this.$refs['cv-qrcode'].toDataURL('image/png')
      var a = document.createElement("a"); //Create <a>
      a.href = data; //Image Base64 Goes here
      a.download = "qrcode.png"; //File name Here
      a.click(); //Downloaded file
    }
  },
  computed: {
    showXXX() {
      // return true
      // 是否部分选择
      return true
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.my-icon {
  color:black;
}
.my-icon:hover {
  /* color:#42b983; */
  color:var(--my-color-green);
}
.td-canvas:hover {
  cursor: pointer;
}
</style>
