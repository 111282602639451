<template>
  <n-space vertical>
    <h1>NANO ID生成器</h1>

    <div>
      <h3 style="margin-top:5px;">字符串长度</h3>
      <n-button style="margin-top:5px;" @click="onClickBtnIdLenDft" :disabled="idLen==21">
        ID长度默认21
      </n-button>
      <n-input-number v-model:value="idLen" :min="2" :max="32" @update:value="onInputIdLenChanged" style="display:inline-block;margin-left:10px;width:120px;"/>
    </div>

    <div>
      <h3 style="margin-top:5px;">可选字符集</h3>
      <div style="margin-top:5px;">
        <n-button style="" @click="dict=dictDefault" :disabled="dict==dictDefault">
          默认(64)
        </n-button>
        <n-button  style="margin-left:5px;" @click="dict=dictNumber" :disabled="dict==dictNumber">
          数字(10)
        </n-button>
        <n-button  style="margin-left:5px;" @click="dict=dictLowercase" :disabled="dict==dictLowercase">
          小写字母(26)
        </n-button>
        <n-button  style="margin-left:5px;" @click="dict=dictUppercase" :disabled="dict==dictUppercase">
          大写字母(26)
        </n-button>
        <n-button  style="margin-left:5px;" @click="dict=dictNumberChar" :disabled="dict==dictNumberChar">
          数字字母(62)
        </n-button>
      </div>
      <div style="margin-top:5px;">
        <n-button  style="margin-left:5px;" @click="dict=dictHexLowercase" :disabled="dict==dictHexLowercase">
          16进制小写字母(16)
        </n-button>
        <n-button  style="margin-left:5px;" @click="dict=dictHexUppercase" :disabled="dict==dictHexUppercase">
          16进制大写字母(16)
        </n-button>
      </div>
      <div style="margin-top:5px;">
        <n-button style="" @click="dict=dictTiangan" :disabled="dict==dictTiangan">
          天干(10)
        </n-button>
        <n-button style="margin-left:5px;" @click="dict=dictDizhi" :disabled="dict==dictDizhi">
          地支(12)
        </n-button>
        <n-button style="margin-left:5px;" @click="dict=dictTianganDizhi" :disabled="dict==dictTianganDizhi">
          天干地支(22)
        </n-button>
      </div>
      <n-input
        v-model:value="dict"
        type="textarea"
        placeholder="字符集"
        style="width:500px; margin-top:5px;font-size:30px;"
      />
    </div>

    <br/>
    <div>
      <span style="display:inline-block;">
        <n-tooltip trigger="hover" style="">
          <template #trigger>
            <n-button text style="" @click="onClickBtnMakeNew">
              <font-awesome-icon icon="refresh" class="icon" style="width:30px;height:30px;"></font-awesome-icon>
            </n-button>
          </template>
          生成新ID
        </n-tooltip>
      </span>
      <span style="display:inline-block;">
        <n-tooltip trigger="hover" style="">
          <template #trigger>
            <n-button text style="" @click="onClickCopy(formattedId)">
              <font-awesome-icon icon="clipboard" class="icon" style="width:30px;height:30px;margin-left:10px;"></font-awesome-icon>
            </n-button>
          </template>
          拷贝到剪贴板
        </n-tooltip>
      </span>
    </div>

    <div>
      <span
        style="display:inline-block;width:auto;font-size:30px;border-style:solid;border-width:1px;border-color:lightgray;padding:15px;">
        {{ formattedId }}</span>
    </div>

    <br/>
    <div>
      <n-button style="" @click="onClickBtnMakeMany">
        批量生成
      </n-button>
      <n-input-number v-model:value="manyNumber" :min="1" :max="1000" style="display:inline-block;margin-left:10px;width:120px;"/>
    </div>

    <br/>
    <div>
      <span style="display:inline-block;margin-right:10px;">分隔符:</span>
      <div style="display:inline-block;margin-left:5px;">
        <n-switch v-model:value="delimiterUnix" :disabled="delimiterUnix" @update:value="onChangeDelimiter('unix')"/>
        <span style="display:inline-block;margin-left:5px;">UNIX换行(\n)</span>
      </div>
      <div style="display:inline-block;margin-left:5px;">
        <n-switch v-model:value="delimiterWin" :disabled="delimiterWin" @update:value="onChangeDelimiter('win')"/>
        <span style="display:inline-block;margin-left:5px;">Windows换行(\r\n)</span>
      </div>
      <div style="display:inline-block;margin-left:5px;">
        <n-switch v-model:value="delimiterCust" :disabled="delimiterCust" @update:value="onChangeDelimiter('cust')"/>
        <span style="display:inline-block;margin-left:5px;">自定义</span>
        <n-input maxlength="10" show-count v-model:value="custDelimiter" style="width:120px;"/>
      </div>
    </div>

  </n-space>
</template>
<script>
// import { defineComponent } from "vue";
// import { h, ref, reactive } from "vue";
// import { ref } from "vue";
import { useMessage } from "naive-ui";
import useClipboard from 'vue-clipboard3'
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import { faRefresh } from "@fortawesome/free-solid-svg-icons/faRefresh";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
import FileSaver from "file-saver";
// import { nanoid } from 'nanoid'
import { customAlphabet } from 'nanoid'

export default {
  components: {
  },
  data() {
      return {
        idLen: 21,
        dict: '',
        dictDefault: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz-',
        dictNumber: '0123456789',
        dictHexLowercase: '0123456789abcdef',
        dictHexUppercase: '0123456789ABCDEF',
        dictLowercase: 'abcdefghijklmnopqrstuvwxyz',
        dictUppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        dictNumberChar: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
        dictTiangan: '甲乙丙丁戊己庚辛壬癸',
        dictDizhi: '子丑寅卯辰巳午未申酉戌亥',
        dictTianganDizhi: '甲乙丙丁戊己庚辛壬癸子丑寅卯辰巳午未申酉戌亥',
        madeId: '',
        manyNumber: 1,
        delimiterType: 'unix',
        delimiterUnix: true,
        delimiterWin: false,
        delimiterCust: false,
        custDelimiter: ',',
      }
  },
  setup() {
    window.$message = useMessage()
    library.add(faClipboard)
    library.add(faRefresh)
    return {
    };
  },
  mounted() {
    this.dict = this.dictDefault
    this.refreshId()
  },
  methods: {
    error() {
      window.$message.error("")
    },
    makeId() {
      const nanoid = customAlphabet(this.dict, this.idLen)
      return nanoid() //=> "V1StGXR8_Z5jdHi6B-myT"
    },
    refreshId() {
      this.madeId = this.makeId()
    },
    onClickBtnMakeNew() {
      this.refreshId()
    },
    onClickBtnMakeMany() {
      let ids = []
      for (let i =0; i < this.manyNumber; i++) {
        let id = this.makeId()
        // if (!this.withDash) {
        //   id = id.replace(/-/g, '')
        // }
        // if (this.uppercase) {
        //   id = id.toUpperCase()
        // }
        ids.push(id)
      }
      let data = ''
      switch (this.delimiterType) {
        case 'unix':
          data = ids.join('\n')
          break;
        case 'win':
          data = ids.join('\r\n')
          break;
        case 'cust':
          data = ids.join(this.custDelimiter)
          break;
      }
      var blob = new Blob([data], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, "nanoids.txt");
    },
    onClickCopy(arg) {
      const { toClipboard } = useClipboard();
      toClipboard(arg)
    },
    onChangeDelimiter(type) {
      switch(type) {
        case 'unix':
          this.delimiterWin = false
          this.delimiterCust = false
          this.delimiterType = 'unix'
          break;
        case 'win':
          this.delimiterUnix = false
          this.delimiterCust = false
          this.delimiterType = 'win'
          break;
        case 'cust':
          this.delimiterUnix = false
          this.delimiterWin = false
          this.delimiterType = 'cust'
          break;
      }
    },
    onInputIdLenChanged() {
      this.refreshId()
    },
    onClickBtnIdLenDft() {
      if (this.idLen != 21) {
        this.idLen = 21
        this.refreshId()
      }
    }
  },
  computed: {
    formattedId() {
      let id = this.madeId;

      return id
    },
    showXXX() {
      // return true
      // 是否部分选择
      return true
    }
  },
  beforeUnmount() {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.my-icon {
  color:black;
}
.my-icon:hover {
  /* color:#42b983; */
  color:var(--my-color-green);
}
.n-radio__label:hover {
  cursor: pointer;
}
</style>
