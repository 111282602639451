
export function drawRoundRectPath(ctx, left, top, right, bottom, radius) {
  ctx.beginPath()
  ctx.arc(
    left+radius,
    top+radius,
    radius,
    Math.PI, 1.5*Math.PI
  )
  ctx.arc(
    right-radius,
    top+radius,
    radius,
    1.5*Math.PI, 2*Math.PI
  )
  ctx.arc(
    right-radius,
    bottom-radius,
    radius,
    0, 0.5*Math.PI
  )
  ctx.arc(
    left+radius,
    bottom-radius,
    radius,
    0.5*Math.PI, Math.PI
  )
  ctx.closePath()
}
  