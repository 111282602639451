<template>
  <n-space vertical>
    <n-table :bordered="true" :single-line="false" style="width:auto;margin:10px auto;font-size:20px;">
      <tbody>
        <tr>
          <td>
            <div style="float:right;">
              <n-tooltip trigger="hover">
                <template #trigger>
                  <n-icon @click="onClickCopy(utcDateTime)" style="margin-top:0px;" size="30">
                    <clipboard-outline class="my-icon" />
                  </n-icon>
                </template>
                拷贝到剪贴板
              </n-tooltip>
            </div>
            <div style="float:right;color:DarkBlue;margin-top:0px;">{{ timestamp }}&nbsp;</div>
            <div style="float:right;margin-top:0px;">UNIX时间戳&nbsp;</div>
            <div style="float:right;margin-top:0px;">
              <n-tooltip trigger="hover">
                <template #trigger>
                  <n-icon v-show="timerStopped" @click="onClickStartTimer" style="margin-top:0px;" size="30">
                    <caret-forward-circle-outline class="my-icon" />
                  </n-icon>
                </template>
                开始
              </n-tooltip>
            </div>
            <div style="float:right;margin-top:0px;">
              <n-tooltip trigger="hover">
                <template #trigger>
                  <n-icon v-show="!timerStopped" @click="onClickStopTimer" style="margin-top:0px;" size="30">
                    <pause-circle-outline class="my-icon"/>
                  </n-icon>
                </template>
                停止
              </n-tooltip>
            </div>
          </td>
          <td>
            <div style="float:left;margin-top:0px;">UTC时间&nbsp;</div>
            <div style="float:left;margin-top:0px;color:DarkBlue;">{{ utcDateTime }}</div>
            <div style="float:left;margin-right:0px;">
              <n-tooltip trigger="hover">
                <template #trigger>
                  <n-icon @click="onClickCopy(utcDateTime)" style="margin-top:0px;" size="30">
                    <clipboard-outline class="my-icon" />
                  </n-icon>
                </template>
                拷贝到剪贴板
              </n-tooltip>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <n-select v-model:value="offset1" :options="hourOptions" style="width:70px;float:right;margin-top:0px;"/>
            <n-button :disabled="localOffset==offset1" style="float:right;margin-top:0px;" @click="offset1=localOffset">本地时区({{ localOffset }})</n-button>
            <div style="float:right;margin-top:0px;">时区&nbsp;</div>
          </td>
          <td>
            <div style="float:left;color:DarkBlue;margin-top:2px;">{{ offsetDateTime }}</div>
            <div style="float:left;margin-top:2px;">
              <n-tooltip trigger="hover">
                <template #trigger>
                  <n-icon @click="onClickCopy(offsetDateTime)" style="" size="30">
                    <clipboard-outline class="my-icon" />
                  </n-icon>
                </template>
                拷贝到剪贴板
              </n-tooltip>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <n-icon style="margin-top:2px;float:right;" size="30">
              <arrow-forward-outline class="my-icon"/>
            </n-icon>
            <n-input-number
              v-model:value="inputTimestamp"
              placeholder="时间戳"
              :min="0"
              :max="999999999999"
              style="float:right;"
            />
            <div style="float:right;">
              <n-tooltip trigger="hover">
                <template #trigger>
                  <n-icon @click="onClickNowTimestamp" style="margin-top:2px;" size="30">
                    <time-outline class="my-icon"/>
                  </n-icon>
                </template>
                当前时间戳
              </n-tooltip>
            </div>
            <div style="float:right;margin-top:2px;">时间戳&nbsp;</div>
          </td>
          <td>
            <n-icon style="margin-top:0px;float:left;" size="30">
              <arrow-forward-outline class="my-icon"/>
            </n-icon>
            <span style="display:inline-block;float:left;margin-top:0px;">&nbsp;时区&nbsp;</span>
            <n-button :disabled="localOffset==offset2" style="display:inline-block;float:left;margin-top:0px;" @click="offset2=localOffset">本地时区({{ localOffset }})</n-button>
            <n-select v-model:value="offset2" :options="hourOptions" style="width:70px;float:left;margin-top:0px;margin-left:5px;"/>
            <span style="display:inline-block;float:left;color:DarkBlue;margin-left:5px;">{{ timestampDateTime }}</span>
            <div style="float:left;">
              <n-tooltip trigger="hover">
                <template #trigger>
                  <n-icon @click="onClickCopy(timestampDateTime)" style="margin-top:0px;" size="30">
                    <clipboard-outline class="my-icon" />
                  </n-icon>
                </template>
                拷贝到剪贴板
              </n-tooltip>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <n-icon style="margin-top:0px;margin-left:0px;float:right;" size="30">
              <arrow-forward-outline class="my-icon" />
            </n-icon>
            <n-date-picker
              v-model:formatted-value="pickerDateTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              clearable
              style="float:right;margin-top:0px;margin-left:5px;"/>
            <n-select v-model:value="offset3" :options="hourOptions" style="width:70px;float:right;margin-top:0px;margin-left:5px;"/>
            <n-button :disabled="localOffset==offset3" style="display:inline-block;float:right;margin-top:0px;" @click="offset3=localOffset">本地时区({{ localOffset }})</n-button>
            <div style="float:right;margin-top:0px;">时区&nbsp;</div>
          </td>
          <td>
            <n-icon style="margin-top:0px;margin-left:0px;float:left;" size="30">
              <arrow-forward-outline class="my-icon"/>
            </n-icon>
            <span style="display:inline-block;float:left;margin-left:10px;margin-top:0px;">UNIX时间戳&nbsp;</span>
            <span style="display:inline-block;float:left;margin-top:0px;color:DarkBlue;">{{ datetimeTimestamp }}</span>
            <div style="float:left;">
              <n-tooltip trigger="hover">
                <template #trigger>
                  <n-icon @click="onClickCopy(''+datetimeTimestamp)" style="margin-top:0px;" size="30">
                    <clipboard-outline class="my-icon"/>
                  </n-icon>
                </template>
                拷贝到剪贴板
              </n-tooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </n-table>
  </n-space>
</template>
<script>
// import { defineComponent } from "vue";
// import { h, ref, reactive } from "vue";
import { ref } from "vue";
import { useMessage } from "naive-ui";
import moment from "moment"
import { ArrowForwardOutline, CaretForwardCircleOutline, ClipboardOutline, PauseCircleOutline, TimeOutline } from "@vicons/ionicons5";
import useClipboard from 'vue-clipboard3'

export default {
  components: {
    'caret-forward-circle-outline': CaretForwardCircleOutline,
    'pause-circle-outline': PauseCircleOutline,
    'clipboard-outline': ClipboardOutline,
    'arrow-forward-outline': ArrowForwardOutline,
    'time-outline': TimeOutline,
  },
  data() {
      return {
        timerStopped: false,
        timestamp: 0,
        inputTimestamp: 0,
        pickerDateTime: '2000-01-01 00:00:00',
        timer: null,// 初始定时器变量名为null
      }
  },
  setup() {
    window.$message = useMessage()
    return {
      hourOptions: [
        { label: "+12", value: 12 },
        { label: "+11", value: 11 },
        { label: "+10", value: 10 },
        { label: "+9", value: 9 },
        { label: "+8", value: 8 },
        { label: "+7", value: 7 },
        { label: "+6", value: 6 },
        { label: "+5", value: 5 },
        { label: "+4", value: 4 },
        { label: "+3", value: 3 },
        { label: "+2", value: 2 },
        { label: "+1", value: 1 },
        { label: "0", value: 0 },
        { label: "-1", value: -1 },
        { label: "-2", value: -2 },
        { label: "-3", value: -3 },
        { label: "-4", value: -4 },
        { label: "-5", value: -5 },
        { label: "-6", value: -6 },
        { label: "-7", value: -7 },
        { label: "-8", value: -8 },
        { label: "-9", value: -9 },
        { label: "-10", value: -10 },
        { label: "-11", value: -11 },
        { label: "-12", value: -12 },
      ],
      offset1: ref(0),
      offset2: ref(0),
      offset3: ref(0),
    };
  },
  mounted() {
    // this.getList()
    this.timestamp = parseInt((new Date).getTime()/1000)
    this.inputTimestamp = this.timestamp
    this.timerStopped = true
    this.pickerDateTime = moment(this.timestamp*1000).format('YYYY-MM-DD HH:mm:ss')
    this.startTimer()
    const localOffset = moment((new Date).getTime()).utcOffset()/60
    this.offset1 = localOffset
    this.offset2 = localOffset
    this.offset3 = localOffset
  },
  methods: {
    error() {
      window.$message.error("")
    },
    startTimer() {
      if (!this.timerStopped) {
        return
      }
      this.timerStopped = false
      // 将定时器名字赋值到变量中
      this.timer = setInterval(() => {
        this.timestamp = parseInt((new Date).getTime()/1000)
      }, 1000);
    },
    stopTimer() {
      if (this.timerStopped) {
        return
      }
      this.timerStopped = true
      clearInterval(this.timer);
      this.timer = null // 这里最好清除一下，回归默认值
      // 众所周知，定时器返回一个随机整数，用于表示定时器的编号，后面通过名字可以取消这个定时器的执行。
      // console.log(this.timer);
    },
    onClickStartTimer() {
      this.startTimer()
    },
    onClickStopTimer() {
      this.stopTimer()
    },
    onClickCopy(arg) {
      const { toClipboard } = useClipboard();
      toClipboard(arg)
    },
    onClickNowTimestamp() {
      this.inputTimestamp = this.timestamp
    },
  },
  computed: {
    utcDateTime() {
      return moment(this.timestamp*1000).utc().format('YYYY-MM-DD HH:mm:ss')
    },
    localDateTime() {
      return moment(this.timestamp*1000).format('YYYY-MM-DD HH:mm:ss')
    },
    offsetDateTime() {
      return moment(this.timestamp*1000).utcOffset(this.offset1).format('YYYY-MM-DD HH:mm:ss')
    },
    localOffset() {
      const offset = moment((new Date).getTime()).utcOffset()/60
      return offset > 0 ? '+'+offset : offset
    },
    localTimestampDateTime() {
      return moment(this.inputTimestamp*1000).format('YYYY-MM-DD HH:mm:ss')
    },
    timestampDateTime() {
      return moment(this.inputTimestamp*1000).utcOffset(this.offset2).format('YYYY-MM-DD HH:mm:ss')
    },
    datetimeTimestamp() {
      return moment(this.pickerDateTime).unix()+(this.localOffset-this.offset3)*3600
    },
    showXXX() {
      // return true
      // 是否部分选择
      return true
    }
  },
  beforeUnmount() {
    // js提供的clearInterval方法用来清除定时器
    clearInterval(this.timer);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.my-icon {
  color:black;
}
.my-icon:hover {
  /* color:#42b983; */
  color:var(--my-color-green);
}
</style>
