import http from '@/http'

export function apiGetMyBrowser(params) {
  return http({
    url: '/api/common/my-browser',
    method: 'get',
    params: params
  })
}

