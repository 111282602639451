<template>
  <n-space vertical>
    <h1>条形码生成器</h1>

    <n-table :bordered="true" :single-line="false" style="width:auto;margin:10px auto;font-size:20px;">
      <tbody>
        <tr>
          <td colspan="2">
            <div>
              <span style="float:left">显示条码内容</span>
              <n-switch v-model:value="displayValue" @update:value="refreshCode()" style="float:left;margin-right:5px;margin-top:5px;"/>

              <span style="float:left;margin-left:15px;">文本对齐</span>
              <n-radio-group v-model:value="textAlign" name="textAlignRadioGroup" size="medium" @update:value="refreshCode()" style="float:left;margin-left:15px;">
                <n-radio-button
                  key="left"
                  value="left"
                >
                  左
                </n-radio-button>
                <n-radio-button
                  key="center"
                  value="center"
                >
                  中
                </n-radio-button>
                <n-radio-button
                  key="right"
                  value="right"
                >
                  右
                </n-radio-button>
              </n-radio-group>

              <span style="margin-left:5px;margin-right:5px;">文本位置</span>
              <n-radio-group v-model:value="textPosition" name="textAlignRadioGroup" size="medium" @update:value="refreshCode()">
                <n-radio-button
                  key="top"
                  value="top"
                >
                  顶部
                </n-radio-button>
                <n-radio-button
                  key="bottom"
                  value="bottom"
                >
                  底部
                </n-radio-button>
              </n-radio-group>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <div>
              <span style="float:left;">线条颜色</span>
              <n-color-picker v-model:value="lineColor" :show-alpha="false" :modes="['hex']" style="float:left;width:100px;margin-left:5px;" @update:value="refreshCode()"/>
              <span style="float:left;margin-left:15px;">背景颜色</span>
              <n-color-picker v-model:value="bgColor" :show-alpha="false" :modes="['hex']" style="float:left;width:100px;margin-left:5px;" @update:value="refreshCode()"/>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            条码内容
          </td>
          <td>
            <n-input
              v-model:value="barContent"
              placeholder=""
              style=""
              @update:value="onContentChanged"
            />
          </td>
        </tr>
        <tr>
          <td>
            文本内容
          </td>
          <td>
            <n-input
              v-model:value="barText"
              placeholder=""
              style=""
              clearable
              @update:value="onTextChanged"
            />
          </td>
        </tr>
        <tr>
          <td>
            CODE-128
          </td>
          <td class="td-canvas">
            <canvas id="cv-code128" @click="download('cv-code128')"  ref="cv-code128"></canvas>
          </td>
        </tr>
        <tr>
          <td>
            EAN-2
          </td>
          <td class="td-canvas">
            <canvas id="cv-ean2" @click="download('cv-ean2')"  ref="cv-ean2"></canvas>
          </td>
        </tr>
        <tr>
          <td>
            EAN-5
          </td>
          <td class="td-canvas">
            <canvas id="cv-ean5" @click="download('cv-ean5')"  ref="cv-ean5"></canvas>
          </td>
        </tr>
        <tr>
          <td>
            EAN-8
          </td>
          <td class="td-canvas">
            <canvas id="cv-ean8" @click="download('cv-ean8')"  ref="cv-ean8"></canvas>
          </td>
        </tr>
        <tr>
          <td>
            EAN-13
          </td>
          <td class="td-canvas">
            <canvas id="cv-ean13" @click="download('cv-ean13')"  ref="cv-ean13"></canvas>
          </td>
        </tr>
        <tr>
          <td>
            UPC
          </td>
          <td class="td-canvas">
            <canvas id="cv-upc" @click="download('cv-upc')"  ref="cv-upc"></canvas>
          </td>
        </tr>
        <tr>
          <td>
            CODEBAR
          </td>
          <td class="td-canvas">
            <canvas id="cv-codabar" @click="download('cv-codabar')"  ref="cv-codabar"></canvas>
          </td>
        </tr>
        <tr>
          <td>
            Pharmacode
          </td>
          <td class="td-canvas">
            <canvas id="cv-pharmacode" @click="download('cv-pharmacode')"  ref="cv-pharmacode"></canvas>
          </td>
        </tr>
      </tbody>
    </n-table>

    <div>
      
    </div>

  </n-space>
</template>
<script>
// import { defineComponent } from "vue";
// import { h, ref, reactive } from "vue";
// import { ref } from "vue";
import { useMessage } from "naive-ui";
import JsBarcode from 'jsbarcode';

export default {
  components: {
  },
  data() {
      return {
        barContent: '1234567890123',
        barText: '',
        barHeight: '50',
        barWidth: '5',
        lineColor: '#000',
        bgColor: '#FFF',
        displayValue: true,
        textAlign: 'center',
        textPosition: 'bottom',  
        formatErrors: {
          'code128' : false,
          'ean2' : false,
          'ean5' : false,
          'ean8' : false,
          'ean13' : false,
          'upc' : false,
          'codabar' : false,
          'pharmacode': false,
        }
      }
  },
  setup() {
    window.$message = useMessage()
    return {
    };
  },
  mounted() {
    this.refreshCode()
  },
  methods: {
    error() {
      window.$message.error("")
    },
    refreshCode() {
      const formats = [
        'code128', 'ean2', 'ean5', 'ean8', 'ean13', 'upc', 'codabar', 'pharmacode',
      ]
      for (let i=0; i< formats.length; i++) {
        try{
          JsBarcode('#cv-'+formats[i], this.barContent, {
            format: formats[i],
            lineColor: this.lineColor,
            background: this.bgColor,
            width: this.barWidth,
            height: this.barHeight,
            text: this.barText,
            textAlign: this.textAlign,
            textPosition: this.textPosition,
            displayValue: this.displayValue
          })
        } catch(e) {
          console.log(e)
        }
      }
    },
    onContentChanged(/*val*/) {
      this.refreshCode()
    },
    onTextChanged(/*val*/) {
      this.refreshCode()
    },
    download(name) {
      const data = this.$refs[name].toDataURL('image/png')
      var a = document.createElement("a"); //Create <a>
      a.href = data; //Image Base64 Goes here
      a.download = "barcode.png"; //File name Here
      a.click(); //Downloaded file
    }
  },
  computed: {
    showXXX() {
      // return true
      // 是否部分选择
      return true
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.my-icon {
  color:black;
}
.my-icon:hover {
  /* color:#42b983; */
  color:var(--my-color-green);
}
.td-canvas:hover {
  cursor: pointer;
}
</style>
