import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import naive from 'naive-ui'
import router from './router'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// const router = createRouter({
//     scrollBehavior: () => ({ y: 0 }),
//     // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
//     history: createWebHashHistory(),
//     routes: routes
// });
const pinia = createPinia()
createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(naive).use(pinia).use(router).mount('#app')
