<template>
  <n-space vertical>
    <!-- <h1>进制转化计算器</h1> -->

    <n-table :bordered="true" :single-line="false" style="width:auto;margin:10px auto;font-size:20px;">
      <tbody>
        <tr>
          <td>
            <canvas :width="canvasWidth" :height="canvasWidth" @click="download()" ref="cv-icon"></canvas>
          </td>
          <td style="vertical-align: top;">
            <n-table :bordered="true" :single-line="false" style="width:auto;margin-top:0;font-size:20px;">
              <tbody>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">左侧字</span>
                  </td>
                  <td>
                    <n-input
                      v-model:value="leftWord"
                      placeholder=""
                      style="width:auto;margin-left:15px;"
                      type="text"
                      @update:value="onWordChanged"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">右侧字</span>
                  </td>
                  <td>
                    <n-input
                      v-model:value="rightWord"
                      placeholder=""
                      style="width:auto;margin-left:15px;"
                      type="text"
                      @update:value="onWordChanged"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">背景颜色</span>
                  </td>
                  <td>
                    <n-color-picker v-model:value="bgColor" :show-alpha="true" :modes="['hex']"
                      style="float:left;width:100px;margin-left:10px;" @update:value="refreshIcon()"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">左字颜色</span>
                  </td>
                  <td>
                    <n-color-picker v-model:value="leftWordColor" :show-alpha="true" :modes="['hex']"
                      style="float:left;width:100px;margin-left:10px;" @update:value="refreshIcon()"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">右背景颜色</span>
                  </td>
                  <td>
                    <n-color-picker v-model:value="rightBgColor" :show-alpha="true" :modes="['hex']"
                      style="float:left;width:100px;margin-left:10px;" @update:value="refreshIcon()"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">右字颜色</span>
                  </td>
                  <td>
                    <n-color-picker v-model:value="rightWordColor" :show-alpha="true" :modes="['hex']"
                      style="float:left;width:100px;margin-left:10px;" @update:value="refreshIcon()"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">背景形状</span>
                  </td>
                  <td>
                     <n-radio-group v-model:value="shape"
                      name="shapeRadioGroup"
                      size="medium"
                      style="float:left;margin-left:10px;"
                      @update:value="refreshIcon()">
                      <n-radio-button
                        key="default"
                        value="default"
                      >
                        默认
                      </n-radio-button>
                      <n-radio-button
                        key="rect"
                        value="rect"
                      >
                        矩形
                      </n-radio-button>
                      <n-radio-button
                        key="roundRect"
                        value="roundRect"
                      >
                        圆角
                      </n-radio-button>
                      </n-radio-group>
                  </td>
                </tr>
              </tbody>
             </n-table>
          </td>
        </tr>
      </tbody>
    </n-table>
    <div>
    </div>

  </n-space>
</template>
<script>
// import { defineComponent } from "vue";
// import { h, ref, reactive } from "vue";
// import { ref } from "vue";
import { drawRoundRectPath } from "@/utils/canvas";
import { useMessage } from "naive-ui";

export default {
  components: {
  },
  data() {
      return {
        leftWord: 'Porn',
        rightWord: 'hub',
        bgColor: '#000000',
        borderColor: '#000000',
        leftWordColor: '#FFFFFF',
        rightBgColor: '#F7971D',
        rightWordColor: '#000000',
        canvasWidth: 500,
        fontSize: 10,
        font: '',
        margin: 10,
        shape: 'default',
      }
  },
  setup() {
    window.$message = useMessage()
    return {
    };
  },
  mounted() {
    this.refreshIcon()
  },
  methods: {
    error() {
      window.$message.error("")
    },
    refreshIcon() {
      const canvas = this.$refs['cv-icon']
      const ctx = canvas.getContext('2d')
      this.calculateMatrix(ctx)
      this.drawBackground(ctx)
      this.drawLeftWord(ctx)
      this.drawRightWord(ctx)
    },
    calculateMatrix(ctx) {
      this.margin = Math.floor(this.canvasWidth*0.02)
      const text = this.leftWord + this.rightWord
      this.fontSize = 10
      ctx.font = this.fontSize+'px "微软雅黑"'           //设置字体
      const width  = (ctx.measureText(text).width)
      const ratio = width/(this.canvasWidth-this.margin*5)
      this.fontSize = Math.floor(this.fontSize/ratio)
      ctx.font = this.fontSize+'px "微软雅黑"'           //设置字体
      console.log(ctx.font)
    },
    drawBackground(ctx) {
      ctx.strokeStyle = '#FFFFFF00'
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasWidth);

      ctx.fillStyle = this.bgColor
      if (this.shape === 'rect') {
        ctx.fillRect(
          0, this.canvasWidth/2-this.fontSize/2-(this.canvasWidth/2-this.fontSize/2)/2,
          this.canvasWidth, this.fontSize+(this.canvasWidth/2-this.fontSize/2)
        );
      } else if (this.shape === 'roundRect') {
        const left = 0
        const top = this.canvasWidth/2-this.fontSize/2-(this.canvasWidth/2-this.fontSize/2)/2
        const right = this.canvasWidth
        const bottom = this.canvasWidth/2+this.fontSize/2+(this.canvasWidth/2-this.fontSize/2)/2
        const cornerRedius = this.margin*3
        drawRoundRectPath(ctx, left, top, right, bottom, cornerRedius)
        ctx.fill()
      } else {
        ctx.fillRect(0, 0, this.canvasWidth, this.canvasWidth);
      }
    },
    drawLeftWord(ctx) {
      const x = this.margin
      const y = this.canvasWidth/2+(this.fontSize/2)*0.7
      ctx.fillStyle = this.leftWordColor
      ctx.fillText(this.leftWord, x, y)
      ctx.stroke()
    },
    // drawRoundRectPath(ctx, left, top, right, bottom, radius) {
    //   ctx.beginPath()
    //   ctx.arc(
    //     left+radius,
    //     top+radius,
    //     radius,
    //     Math.PI, 1.5*Math.PI
    //   )
    //   ctx.arc(
    //     right-radius,
    //     top+radius,
    //     radius,
    //     1.5*Math.PI, 2*Math.PI
    //   )
    //   ctx.arc(
    //     right-radius,
    //     bottom-radius,
    //     radius,
    //     0, 0.5*Math.PI
    //   )
    //   ctx.arc(
    //     left+radius,
    //     bottom-radius,
    //     radius,
    //     0.5*Math.PI, Math.PI
    //   )
    //   ctx.closePath()
    // },
    drawRightWord(ctx) {
      const cornerRedius = this.margin
      const width  = (ctx.measureText(this.rightWord).width)
      const rightBgWidth = width + cornerRedius*2
      const left = this.canvasWidth-this.margin-rightBgWidth
      const top = this.canvasWidth/2-this.fontSize/2-cornerRedius
      const right = this.canvasWidth-this.margin
      const bottom = this.canvasWidth/2+this.fontSize/2+cornerRedius

      ctx.fillStyle = this.rightBgColor
      drawRoundRectPath(ctx, left, top, right, bottom, cornerRedius)

      // ctx.fillStyle = this.rightBgColor
      // ctx.beginPath()
      // ctx.arc(
      //   this.canvasWidth-rightBgWidth,
      //   this.canvasWidth/2-this.fontSize/2,
      //   cornerRedius,
      //   Math.PI, 1.5*Math.PI
      // )
      // ctx.arc(
      //   this.canvasWidth-this.margin*2,
      //   this.canvasWidth/2-this.fontSize/2,
      //   cornerRedius,
      //   1.5*Math.PI, 2*Math.PI
      // )
      // ctx.arc(
      //   this.canvasWidth-this.margin*2,
      //   this.canvasWidth/2+this.fontSize/2,
      //   cornerRedius,
      //   0, 0.5*Math.PI
      // )
      // ctx.arc(
      //   this.canvasWidth-rightBgWidth,
      //   this.canvasWidth/2+this.fontSize/2,
      //   cornerRedius,
      //   0.5*Math.PI, Math.PI
      // )
      // ctx.closePath()
      ctx.fill()

      const x = this.canvasWidth - width - this.margin*2
      const y = this.canvasWidth/2+(this.fontSize/2)*0.7
      ctx.fillStyle = this.rightWordColor
      ctx.fillText(this.rightWord, x, y)
      ctx.stroke()
    },
    onWordChanged() {
      this.refreshIcon()
    },
    download() {
      const data = this.$refs['cv-icon'].toDataURL('image/png')
      var a = document.createElement("a"); //Create <a>
      a.href = data; //Image Base64 Goes here
      a.download = "icon.png"; //File name Here
      a.click(); //Downloaded file
    }
  },
  computed: {
    showXXX() {
      // return true
      // 是否部分选择
      return true
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.my-icon {
  color:black;
}
.my-icon:hover {
  /* color:#42b983; */
  color:var(--my-color-green);
}
.td-number:hover {
  cursor: pointer;
}
</style>
