<template>
    <n-menu
      v-model:value="activeKey"
      mode="horizontal"
      :options="menuOptions"
      @update:value="handleUpdateValue"
      style="float:left"
    />
</template>

<script>
// import { defineComponent } from "vue";
import { useMessage } from "naive-ui";
import { NIcon } from "naive-ui";
import { HomeOutline, TimeOutline, CalculatorOutline, DiceOutline, SnowOutline  } from "@vicons/ionicons5";
import { QrCodeOutline, BarcodeOutline, PersonOutline } from "@vicons/ionicons5";
import { h, ref } from "vue";
import { RouterLink } from "vue-router";
function renderIcon(icon) {
  return () => h(NIcon, null, { default: () => h(icon, {style:"color:black;"}) });
}
const menuOptions = [
  {
    label: () => h(
      RouterLink,
      {
        to: {
          name: "MainPage",
          params: {
          }
        },
      },
      { default: () => "Home" }
    ),
    key: "MainPage",
    icon: renderIcon(HomeOutline)
  },  
  {
    label: '时间',
    key: "Time",
    icon: renderIcon(TimeOutline),
    children: [
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "TimeStamp",
              params: {
              }
            },
          },
          { default: () => "时间戳" }
        ),
        key: 'TimeStamp',
        icon: renderIcon(TimeOutline)
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "TimeCalculator",
              params: {
              }
            },
          },
          { default: () => "时间计算" }
        ),
        key: 'TimeCalculator',
        icon: renderIcon(CalculatorOutline)
      },
    ]
  },
  {
    label: 'ID生成器',
    key: "IdGen",
    icon: renderIcon(DiceOutline),
    children: [
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "UuId",
              params: {
              }
            },
          },
          { default: () => "UUID" }
        ),
        key: 'UuId',
        icon: renderIcon(DiceOutline)
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "NanoId",
              params: {
              }
            },
          },
          { default: () => "Nano ID" }
        ),
        key: 'NanoId',
        icon: renderIcon(DiceOutline)
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "FlakeId",
              params: {
              }
            },
          },
          { default: () => "雪花ID" }
        ),
        key: 'FlakeId',
        icon: renderIcon(SnowOutline)
      },
    ]
  },
  {
    label: '条码',
    key: "BarCodeBase",
    icon: renderIcon(BarcodeOutline),
    children: [
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "BarCode",
              params: {
              }
            },
          },
          { default: () => "条形码" }
        ),
        key: 'BarCode',
        icon: renderIcon(BarcodeOutline)
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "QrCode",
              params: {
              }
            },
          },
          { default: () => "二维码" }
        ),
        key: 'QrCode',
        icon: renderIcon(QrCodeOutline)
      },
    ]
  },
  {
    label: '计算',
    key: "CalculatorBase",
    icon: renderIcon(CalculatorOutline),
    children: [
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "BaseCalculator",
              params: {
              }
            },
          },
          { default: () => "进制转换" }
        ),
        key: 'BaseCalculator',
        icon: renderIcon(CalculatorOutline)
      },
    ]
  },
  {
    label: '图标',
    key: "IconBase",
    icon: renderIcon(PersonOutline),
    children: [
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "FanfouIcon",
              params: {
              }
            },
          },
          { default: () => "饭否" }
        ),
        key: 'FanfouIcon',
        icon: renderIcon(PersonOutline)
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "CapFourIcon",
              params: {
              }
            },
          },
          { default: () => "瓶盖" }
        ),
        key: 'CapFourIcon',
        icon: renderIcon(PersonOutline)
      },
      {
        label: () => h(
          RouterLink,
          {
            to: {
              name: "PornHubIcon",
              params: {
              }
            },
          },
          { default: () => "P站" }
        ),
        key: 'PornHubIcon',
        icon: renderIcon(PersonOutline)
      },
    ]
  },
]

export default {
  components: {
  },
  data() {
    return {
      
    }
  },
  setup() {
    window.$message = useMessage()
    return {
      menuOptions,
      info() {
        // message.info("I don't know why nobody told you how to unfold your love", {
        //   keepAliveOnHover: true
        // });
      },
      // error() {
      //   message.error("Once upon a time you dressed so fine");
      // },
      warning() {
        // message.warning("How many roads must a man walk down");
      },
      success() {
        // message.success("'Cause you walked hand in hand With another man in my place");
      },
      loading() {
        // message.loading("If I were you, I will realize that I love you more than any other guy");
      },
      activeKey: ref(''),
    };
  },
  computed: {
    // activeKey() {
    //   console.log(this.$route.name)
    //   return this.$route.name
    // }
  },
  methods: {
    error() {
      window.$message.error("")
    },
    handleUpdateValue (/*key, item*/) {
        // console.log(key, item)
        // console.log(this.activeKey)
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.titlebar {
  /* background: rgb(190, 186, 186); */
  color:white;
  float:left;
  height:100px;
  width:100%;
  padding: 0;
}

.menu-a {
  color:black;
}
.menu-a:hover {
  color:green;
}

</style>
