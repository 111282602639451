import { defineStore } from 'pinia'

// You can name the return value of `defineStore()` anything you want, 
// but it's best to use the name of the store and surround it with `use` 
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const defineStoreProfile = defineStore('profile', {
  state: () => {
    return {
      count: 0,
      id: 0,
      email: '',
      role: 'Guest',
      accessToken: '',
    }
  },
  getters: {
    doubleCount: (state) => state.count * 2,
  },
  actions: {
    increment() {
      this.count++
    },
    saveProfile(profile) {
      this.id = profile.id ? profile.id : 0
      this.email = profile.email ? profile.email : ''
      this.role = profile.role ? profile.role : 'Guest'
      this.accessToken = profile.accessToken ? profile.accessToken : ''
    },
  },
})