<template>
  <n-message-provider>
    <n-config-provider :locale="locale" :date-locale="dateLocale" style="height:100%">
      <n-dialog-provider>
      <n-layout style="height:100%;">
        <n-layout-content style="height:100%;width:100%;" content-style="height:100%;">
          <!-- 路由出口 -->
          <!-- 路由匹配到的组件将渲染在这里 -->
          <router-view style="height:100%;"></router-view>
        </n-layout-content>
      </n-layout>
      </n-dialog-provider>
    </n-config-provider>
  </n-message-provider>
</template>

<script>
// import { useMessage } from "naive-ui";
// import { apiGetMyProfile } from "@/apis/user/profile";
import { defineStoreProfile } from "@/stores/profile";
import { onMounted } from 'vue'
import { zhCN, dateZhCN } from 'naive-ui'

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      locale: zhCN,
      dateLocale: dateZhCN,
    }
  },
  setup() {
    // 页面第一次打开
    onMounted(() => {
      console.log('Component is mounted!')
    })
    // window.$message = useMessage()
    const storeProfile = defineStoreProfile()
    return {
      storeProfile
    }
  },
  mounted() {
    // console.log(this.$route.name)
    // this.getMyProfile()
    // setInterval(() => {
    //   this.counter++
    // }, 1000)
  },
  methods: {
    // getMyProfile() {
    //   apiGetMyProfile().then(response => {
    //     this.storeProfile.saveProfile(response.data)
    //     // console.log(response)
    //     if (response.data.role == 'Guest') {
    //       this.$router.push(
    //         {
    //           name: 'UserLogin',
    //           params: {
    //           }
    //         }
    //       )
    //     }
    //   }).catch(err => {
    //     window.$message.error(err.message)
    //     console.log(err)
    //     this.$router.push(
    //       {
    //         name: 'UserLogin',
    //         params: {
    //         }
    //       }
    //     )
    //   })
    // }
    // reverseMessage() {
    //   this.message = this.message
    //     .split('')
    //     .reverse()
    //     .join('')
    // }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top:0px;
  height: 100%;
}
html {
  height:100%;
  --my-color-green: #42b983;
}
body {
  height:100%;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 5px;
}
h3 {
  margin: 40px 0 0;
}
a {
  color: #42b983;
}
.n-icon:hover {
  cursor: pointer;
}
</style>
