<template>
  <!-- <div> -->
    <div style="widht:100%;text-align:center;">
      <n-table :bordered="true" :single-line="false" style="width:auto;margin:10px auto;font-size:20px;">
        <tbody>
          <tr>
            <td>IP</td>
            <td>{{ myBrowser.ip }} {{ myBrowser.country }} {{ myBrowser.region }} {{ myBrowser.city }}</td>
          </tr>
          <tr>
            <td>OS</td>
            <td>{{ myBrowser.os }} {{ myBrowser.osVersion }}</td>
          </tr>
          <tr>
            <td>浏览器</td>
            <td>{{ myBrowser.browser }} {{ myBrowser.browserVersion }}</td>
          </tr>
          <tr>
            <td>设备</td>
            <td>{{ myBrowser.device }}</td>
          </tr>
          <tr>
            <td>语言</td>
            <td>{{ myBrowser.language }}</td>
          </tr>
          <tr>
            <td>UserAgent</td>
            <td>{{ myBrowser.userAgent }}</td>
          </tr>
          <tr>
            <td>更新时间</td>
            <td>2022年 12月 03日 星期六 16:00:32 CST</td>
          </tr>
        </tbody>
      </n-table>
    </div>
  <!-- </div> -->
</template>

<script>
// import { defineComponent } from "vue";
// import { reactive } from "vue";
// import { h, ref, reactive } from "vue";
import { useMessage } from "naive-ui";
import { apiGetMyBrowser } from '@/apis/common/common'

export default {
  data() {
      return {
        listQuery: {
          page: 1,
          totalPage: 0,
          limit: 10,
          order: 'descend',
          orderBy: '',
        },
        myBrowser: {},
      }
  },
  setup() {
    window.$message = useMessage()
    return {
      list: [],
    };
  },
  mounted() {
    this.getMyBrowser()
  },
  methods: {
    error() {
      window.$message.error("")
    },
    getMyBrowser() {
      const params = {}
      apiGetMyBrowser(params).then((response) => {
        this.myBrowser = response.data
      }).catch(err => {
        window.$message.error(err.message)
        console.log(err)
      })
    }
  },
  computed: {
      showXXX() {
        // return true
        // 是否部分选择
        return true
      }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
