<template>
  <n-space vertical>
    <h1>进制转化计算器</h1>

    <n-table :bordered="true" :single-line="false" style="width:auto;margin:10px auto;font-size:20px;">
      <tbody>
        <tr>
          <td>
              <n-select
                v-model:value="inputBase"
                :options="baseOptions"
                @update:value="onInputBaseChanged"
                style="display:inline-block;float:left;width:60px"
              />
              <span  style="float:left;margin-left:15px;">进制</span>
          </td>
          <td>
            <n-input
              v-model:value="inputNumber"
              placeholder=""
              @update:value="onInputNumberChanged"
            />
          </td>
        </tr>
        <tr>
          <td>
            <span>2进制</span>
          </td>
          <td class="td-number" @click="onClickCopy(valBin)">
            <span>{{ valBin }}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>8进制</span>
          </td>
          <td class="td-number" @click="onClickCopy(valOct)">
            <span>{{ valOct }}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>10进制</span>
          </td>
          <td class="td-number" @click="onClickCopy(valDec)">
            <span>{{ valDec }}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>16进制</span>
          </td>
          <td class="td-number" @click="onClickCopy(valHex)">
            <span>{{ valHex }}</span>
          </td>
        </tr>
      </tbody>
    </n-table>

    <div>
      
    </div>

  </n-space>
</template>
<script>
// import { defineComponent } from "vue";
// import { h, ref, reactive } from "vue";
// import { ref } from "vue";
import { useMessage } from "naive-ui";
import bc from 'base-conversion'
import useClipboard from 'vue-clipboard3'

export default {
  components: {
  },
  data() {
      return {
        inputNumber: '',
        inputNumber0: '',
        decimalNumber: '0',
        inputBase: 10,
        inputBase0: 10,
      }
  },
  setup() {
    window.$message = useMessage()
    return {
      baseOptions:[
        {
          label: '2',
          value: 2,
        },
        {
          label: '8',
          value: 8,
        },
        {
          label: '10',
          value: 10,
        },
        {
          label: '16',
          value: 16,
        },
      ]
    };
  },
  mounted() {
    // this.refreshCode()
  },
  methods: {
    error() {
      window.$message.error("")
    },
    resetInputNumber() {
      this.inputNumber = ''
      this.inputNumber0 = ''
    },
    onInputNumberChanged() {
      this.inputNumber = this.inputNumber.trim()
      if (this.inputNumber == '') {
        this.resetInputNumber()
      }

      if (this.validateNumber(this.inputNumber)) {
        this.inputNumber0 = this.inputNumber
      } else {
        this.inputNumber = this.inputNumber0
      }
      if (this.inputBase !== 10) {
        let func = bc(this.inputBase, 10)
        this.decimalNumber = func(this.inputNumber)
        this.decimalNumber = this.decimalNumber == '' ? '0' : this.decimalNumber
      } else {
        this.decimalNumber = this.inputNumber
      }
    },
    onInputBaseChanged() {
      if (this.inputBase != this.inputBase0) {
        let func = bc(this.inputBase0, this.inputBase)
        this.inputNumber = func(this.inputNumber)
        this.inputNumber0 = this.inputNumber
      }
      this.inputBase0 = this.inputBase
    },
    validateNumber(number) {
      let func = this.validateDecNumber
      switch (this.inputBase) {
        case 2:
          func = this.validateBinNumber
          break;
        case 8:
          func = this.validateOctNumber
          break;
        case 10:
          func = this.validateDecNumber
          break;
        case 16:
          func = this.validateHexNumber
          break;
      }
      return func(number)
    },
    validateBinNumber(val) {
      const pattern = new RegExp(/^[01]+$/i)
      return pattern.test(val)
    },
    validateOctNumber(val) {
      const pattern = new RegExp(/^[0-7]+$/i)
      return pattern.test(val)
    },
    validateDecNumber(val) {
      const pattern = new RegExp(/^[0-9]+$/i)
      return pattern.test(val)
    },
    validateHexNumber(val) {
      const pattern = new RegExp(/^[0-9A-F]+$/)
      return pattern.test(val)
    },
    onClickCopy(arg) {
      const { toClipboard } = useClipboard();
      toClipboard(arg)
      window.$message.success(
        '拷贝到内存 '+arg
      );
    },
    download() {
      const data = this.$refs['cv-qrcode'].toDataURL('image/png')
      var a = document.createElement("a"); //Create <a>
      a.href = data; //Image Base64 Goes here
      a.download = "qrcode.png"; //File name Here
      a.click(); //Downloaded file
    }
  },
  computed: {
    valBin() {
      let func = bc(10, 2)
      const rtn = func(''+this.decimalNumber)
      return rtn == '' ? '0' : rtn
    },
    valOct() {
      let func = bc(10, 8)
      const rtn = func(''+this.decimalNumber)
      return rtn == '' ? '0' : rtn
    },
    valDec() {
      let func = bc(10, 10)
      const rtn = func(''+this.decimalNumber)
      return rtn == '' ? '0' : rtn
    },
    valHex() {
      let func = bc(10, 16)
      const rtn = func(''+this.decimalNumber)
      return rtn == '' ? '0' : rtn
    },
    showXXX() {
      // return true
      // 是否部分选择
      return true
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.my-icon {
  color:black;
}
.my-icon:hover {
  /* color:#42b983; */
  color:var(--my-color-green);
}
.td-number:hover {
  cursor: pointer;
}
</style>
