<template>
  <n-space vertical>
    <h1>UUID生成器</h1>
    <n-radio-group v-model:value="idType" name="idTypeRadiobuttongroup" size="medium" @update:value="onTypeBtnChecked">
      <n-radio-button
        key="v1"
        value="v1"
      >
        Version1
      </n-radio-button>
      <n-radio-button
        key="v4"
        value="v4"
      >
        Version4
      </n-radio-button>
      <n-radio-button
        key="nil"
        value="nil"
      >
        NIL
      </n-radio-button>
    </n-radio-group>

    <br/>
    <div>
      <n-switch v-model:value="withDash" /><span style="margin-left:10px;">连字符</span>
      <n-switch v-model:value="uppercase" style="margin-left:10px;"/><span style="margin-left:10px;">大写字母</span>
    </div>

    <br/>
    <div>
      <span style="display:inline-block;">
        <n-tooltip trigger="hover" style="">
          <template #trigger>
            <n-button text style="" @click="onClickBtnMakeNew" :disabled="idType=='nil'">
              <font-awesome-icon icon="refresh" class="icon" style="width:30px;height:30px;"></font-awesome-icon>
            </n-button>
          </template>
          生成新ID
        </n-tooltip>
      </span>
      <span style="display:inline-block;">
        <n-tooltip trigger="hover" style="">
          <template #trigger>
            <n-button text style="" @click="onClickCopy(formattedId)">
              <font-awesome-icon icon="clipboard" class="icon" style="width:30px;height:30px;"></font-awesome-icon>
            </n-button>
          </template>
          拷贝到剪贴板
        </n-tooltip>
      </span>
    </div>

    <div>
      <span
        style="display:inline-block;width:auto;font-size:30px;border-style:solid;border-width:1px;border-color:lightgray;padding:15px;">
        {{ formattedId }}</span>
    </div>

    <br/>
    <div>
      <n-button style="" @click="onClickBtnMakeMany" :disabled="idType=='nil'">
        批量生成
      </n-button>
      <n-input-number v-model:value="manyNumber" :min="1" :max="1000" style="display:inline-block;margin-left:10px;width:120px;"/>
    </div>

    <br/>
    <div>
      <span style="display:inline-block;margin-right:10px;">分隔符:</span>
      <div style="display:inline-block;margin-left:5px;">
        <n-switch v-model:value="delimiterUnix" :disabled="delimiterUnix" @update:value="onChangeDelimiter('unix')"/>
        <span style="display:inline-block;margin-left:5px;">UNIX换行(\n)</span>
      </div>
      <div style="display:inline-block;margin-left:5px;">
        <n-switch v-model:value="delimiterWin" :disabled="delimiterWin" @update:value="onChangeDelimiter('win')"/>
        <span style="display:inline-block;margin-left:5px;">Windows换行(\r\n)</span>
      </div>
      <div style="display:inline-block;margin-left:5px;">
        <n-switch v-model:value="delimiterCust" :disabled="delimiterCust" @update:value="onChangeDelimiter('cust')"/>
        <span style="display:inline-block;margin-left:5px;">自定义</span>
        <n-input maxlength="10" show-count v-model:value="custDelimiter" style="width:120px;"/>
      </div>
    </div>

  </n-space>
</template>
<script>
// import { defineComponent } from "vue";
// import { h, ref, reactive } from "vue";
// import { ref } from "vue";
import { useMessage } from "naive-ui";
import { v1 as uuidv1, v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import useClipboard from 'vue-clipboard3'
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import { faRefresh } from "@fortawesome/free-solid-svg-icons/faRefresh";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
import FileSaver from "file-saver";

export default {
  components: {
  },
  data() {
      return {
        withDash: true,
        uppercase: false,
        idType: 'v4',
        madeId: '',
        manyNumber: 1,
        delimiterType: 'unix',
        delimiterUnix: true,
        delimiterWin: false,
        delimiterCust: false,
        custDelimiter: ',',
      }
  },
  setup() {
    window.$message = useMessage()
    library.add(faClipboard)
    library.add(faRefresh)
    return {
    };
  },
  mounted() {
    this.refreshId()
  },
  methods: {
    error() {
      window.$message.error("")
    },
    makeId() {
      if (this.idType === 'v1') {
        return uuidv1()
      } else if (this.idType === 'v4') {
        return uuidv4()
      } else {
        return NIL_UUID
      }
    },
    refreshId() {
      this.madeId = this.makeId()
    },
    onTypeBtnChecked(/*val*/) {
      this.refreshId()
    },
    onClickBtnMakeNew() {
      this.refreshId()
    },
    onClickBtnMakeMany() {
      let ids = []
      for (let i =0; i < this.manyNumber; i++) {
        let id = this.makeId()
        if (!this.withDash) {
          id = id.replace(/-/g, '')
        }
        if (this.uppercase) {
          id = id.toUpperCase()
        }
        ids.push(id)
      }
      let data = ''
      switch (this.delimiterType) {
        case 'unix':
          data = ids.join('\n')
          break;
        case 'win':
          data = ids.join('\r\n')
          break;
        case 'cust':
          data = ids.join(this.custDelimiter)
          break;
      }
      var blob = new Blob([data], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, "uuids.txt");
    },
    onClickCopy(arg) {
      const { toClipboard } = useClipboard();
      toClipboard(arg)
    },
    onChangeDelimiter(type) {
      switch(type) {
        case 'unix':
          this.delimiterWin = false
          this.delimiterCust = false
          this.delimiterType = 'unix'
          break;
        case 'win':
          this.delimiterUnix = false
          this.delimiterCust = false
          this.delimiterType = 'win'
          break;
        case 'cust':
          this.delimiterUnix = false
          this.delimiterWin = false
          this.delimiterType = 'cust'
          break;
      }
    },
  },
  computed: {
    formattedId() {
      let id = this.madeId;
      if (!this.withDash) {
        id = id.replace(/-/g, '')
      }
      if (this.uppercase) {
        id = id.toUpperCase()
      }

      return id
    },
    showXXX() {
      // return true
      // 是否部分选择
      return true
    }
  },
  beforeUnmount() {
    // js提供的clearInterval方法用来清除定时器
    clearInterval(this.timer);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.my-icon {
  color:black;
}
.my-icon:hover {
  /* color:#42b983; */
  color:var(--my-color-green);
}
.n-radio__label:hover {
  cursor: pointer;
}
</style>
