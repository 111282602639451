<template>
<div style="height:100%;">
  <NavBar />
  <TitleBar />
  <div class="h-bar"/>
  <MenuBar />
  <div class="h-bar"/>
  <!-- 路由出口 -->
  <!-- 路由匹配到的组件将渲染在这里 -->
  <router-view></router-view>
  
</div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import TitleBar from './components/TitleBar.vue'
import MenuBar from './components/MenuBar.vue'
// import { useMessage } from "naive-ui";
export default {
  name: 'App',
  components: {
    NavBar,
    TitleBar,
    MenuBar,
  },
  // data() {
  //   return {
  //     message: 'You loaded this page on ' + new Date().toLocaleString()
  //   }
  // },
  setup() {
    return {
    }
    // window.$message = useMessage()
  },
  mounted() {
    // console.log(this.$route.name)
    // setInterval(() => {
    //   this.counter++
    // }, 1000)
  },
  methods: {
  }
}
</script>

<style>
.n-icon {
  color:white;
  /* color:rgba(1,2,3,4); */
}
.n-divider .n-divider__line {
  height:2px;
}
.n-layout-scroll-container {
  height:100%;
}
/* .n-layout-scroll-container {
  padding:0;
} */
</style>
<style scoped>
.h-bar {
  height:1px;
  width:auto;
  background-color:gray;
  margin-left:5px;
  margin-right:5px;
  clear:both;
}
</style>
<style scoped>

</style>
