<template>
  <n-space vertical>
    <!-- <h1>进制转化计算器</h1> -->

    <n-table :bordered="true" :single-line="false" style="width:auto;margin:10px auto;font-size:20px;">
      <tbody>
        <tr>
          <td>
            <canvas :width="canvasWidth" :height="canvasWidth" @click="download()" ref="cv-icon"></canvas>
          </td>
          <td style="vertical-align: top;">
            <n-table :bordered="false" :single-line="false" style="width:auto;margin-top:0;font-size:20px;">
              <tbody>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">大字</span>
                    <n-input
                      v-model:value="bigWord"
                      placeholder=""
                      style="width:100px;margin-left:15px;"
                      type="text"
                      @update:value="onWordChanged"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">小字</span>
                    <n-input
                      v-model:value="smallWord"
                      placeholder=""
                      style="width:200px;margin-left:15px;"
                      type="text"
                      @update:value="onWordChanged"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">外背景颜色</span>
                    <n-color-picker v-model:value="bgColor1" :show-alpha="true" :modes="['hex']"
                      style="float:left;width:100px;margin-left:10px;" @update:value="refreshIcon()"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">内背景颜色</span>
                    <n-color-picker v-model:value="bgColor2" :show-alpha="true" :modes="['hex']"
                      style="float:left;width:100px;margin-left:10px;" @update:value="refreshIcon()"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">大字颜色</span>
                    <n-color-picker v-model:value="bigWordColor" :show-alpha="true" :modes="['hex']"
                      style="float:left;width:100px;margin-left:10px;" @update:value="refreshIcon()"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">小字颜色</span>
                    <n-color-picker v-model:value="smallWordColor" :show-alpha="true" :modes="['hex']"
                      style="float:left;width:100px;margin-left:10px;" @update:value="refreshIcon()"/>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style="display:inline-block;float:left;">角度</span>
                    <n-button style="margin-left:5px;float:left;" @click="changeAngle(-5)">左</n-button>
                    <span style="display:inline-block;margin-left:15px;margin-right:10px;float:left;width:60px;">{{ angle }}度 </span>
                    <n-button style="margin-left:5px;float:left;" @click="changeAngle(5)">右</n-button>
                  </td>
                </tr>
              </tbody>
             </n-table>
          </td>
        </tr>
      </tbody>
    </n-table>
    <div>
    </div>

  </n-space>
</template>
<script>
// import { defineComponent } from "vue";
// import { h, ref, reactive } from "vue";
// import { ref } from "vue";
import { useMessage } from "naive-ui";

export default {
  components: {
  },
  data() {
      return {
        bigWord: '恭喜發財',
        smallWord: 'SHOW ME THE MONEY',
        bgColor1: '#FFFFFF',
        bgColor2: '#0000FF',
        borderColor: '#000000',
        bigWordColor: '#FFFFFF',
        smallWordColor: '#FFFF00',
        canvasWidth: 500,
        angle: 0,
      }
  },
  setup() {
    window.$message = useMessage()
    return {
    };
  },
  mounted() {
    this.refreshIcon()
  },
  methods: {
    error() {
      window.$message.error("")
    },
    refreshIcon() {
      const canvas = this.$refs['cv-icon']
      const ctx = canvas.getContext('2d')
      this.drawBackground(ctx)
      this.drawBigWord(ctx)
    },
    drawBackground(ctx) {
      ctx.strokeStyle = '#FFFFFF00'
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasWidth);

      ctx.fillStyle = this.bgColor1
      ctx.fillRect(0, 0, this.canvasWidth, this.canvasWidth)

      const radius1 = this.canvasWidth/2
      const radius2 = radius1 - this.canvasWidth*0.1

      const grad = ctx.createRadialGradient(
        this.canvasWidth/2, this.canvasWidth/2, radius2,
        this.canvasWidth/2, this.canvasWidth/2, radius1
      );
      grad.addColorStop(0, this.bgColor2);
      grad.addColorStop(1, this.bgColor1);
      ctx.fillStyle = grad

      ctx.fillRect(0, 0, this.canvasWidth, this.canvasWidth);
    },
    drawBigWord(ctx) {
      const words = this.bigWord.split('')
      let a = ''
      if (words.length > 0) {
        a = words[0]
      }
      let b = ''
      if (words.length > 1) {
        b = words[1]
      }
      let c = ''
      if (words.length > 2) {
        c = words[2]
      }
      let d = ''
      if (words.length > 3) {
        d = words[3]
      }
      const bigFontSize = Math.floor(this.canvasWidth*0.3)
      ctx.font = bigFontSize+'px "微软雅黑"';           //设置字体
      ctx.save()
      ctx.translate(this.canvasWidth/2, this.canvasWidth/2)
      ctx.rotate(Math.PI*(this.angle/180))
      let x = 0
      let y = 0
      const shadowColor = '#00000044'

      const fontWidth1 = ctx.measureText(a).width
      x = -(this.canvasWidth/6+fontWidth1/2)
      y = -(this.canvasWidth/6-fontWidth1/4)
      ctx.fillStyle = shadowColor
      ctx.fillText(a, x + 10, y + 10)
      ctx.fillStyle = this.bigWordColor
      ctx.fillText(a, x, y)

      const fontWidth2 = ctx.measureText(b).width
      x = this.canvasWidth/6-fontWidth2/2
      ctx.fillStyle = shadowColor
      ctx.fillText(b, x + 10, y + 10)
      ctx.fillStyle = this.bigWordColor
      ctx.fillText(b, x, y)

      const fontWidth3 = ctx.measureText(c).width
      x = -160
      x = -(this.canvasWidth/6+fontWidth3/2)
      y = 140
      y = (this.canvasWidth/6+fontWidth3/2)
      ctx.fillStyle = shadowColor
      ctx.fillText(c, x + 10, y + 10)
      ctx.fillStyle = this.bigWordColor
      ctx.fillText(c, x, y)

      const fontWidth4 = ctx.measureText(d).width
      x = this.canvasWidth/6-fontWidth4/2
      ctx.fillStyle = shadowColor
      ctx.fillText(d, x + 10, y + 10)
      ctx.fillStyle = this.bigWordColor
      ctx.fillText(d, x, y)
      ctx.stroke()

      const smallFontSize = Math.floor(this.canvasWidth*0.06)
      ctx.font = smallFontSize+'px "微软雅黑"';           //设置字体
      const smallFontWidth = ctx.measureText(this.smallWord).width
      const smallFontHeight = this.smallWord.length>0 ? ctx.measureText(this.smallWord[0]).width : 0
      x = -(smallFontWidth/2)
      y = smallFontHeight*0.6
      ctx.fillStyle = this.smallWordColor
      ctx.fillText(this.smallWord, x, y)
      ctx.stroke()

      ctx.restore()
    },
    onWordChanged() {
      this.refreshIcon()
    },
    changeAngle(degree){
      this.angle += degree
      if (this.angle + degree > 180) {
        this.angle = 180
      } else if (this.angle + degree < -180) {
        this.angle = 180
      }
      this.refreshIcon()
    },
    download() {
      const data = this.$refs['cv-icon'].toDataURL('image/png')
      var a = document.createElement("a"); //Create <a>
      a.href = data; //Image Base64 Goes here
      a.download = "icon.png"; //File name Here
      a.click(); //Downloaded file
    }
  },
  computed: {
    showXXX() {
      // return true
      // 是否部分选择
      return true
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.my-icon {
  color:black;
}
.my-icon:hover {
  /* color:#42b983; */
  color:var(--my-color-green);
}
.td-number:hover {
  cursor: pointer;
}
</style>
